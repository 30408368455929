import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { supabase } from './supabaseClient'; // Import the Supabase client
import logo from './assets/header.png';

function Login() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    const handleLogin = async (e) => {
        e.preventDefault();
        const { error, session } = await supabase.auth.signInWithPassword({ email, password });
        if (error) {
            setError(error.message);
        } else {
            navigate('/home');
        }
    };

    return (
        <>
            <div className="auth-container">
                <header className="app-header">
                    <div className="image-container">
                        <img src={logo} className="app-logo-home" alt="logo" />
                    </div>
                    <form onSubmit={handleLogin} className="intro-home">
                        <div className='intro'>
                            Welcome to the ARCH App account deletion page.
                            <br /><br />
                            Please Login with your email and password to delete your account.
                        </div>
                        <br />
                        <br />

                        {error && <p className="error">{error}</p>}
                        <div className="input-group">
                            <label htmlFor="email">Email</label>
                            <input
                                type="email"
                                id="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                            />
                        </div>
                        <div className="input-group">
                            <label htmlFor="password">Password</label>
                            <input
                                type="password"
                                id="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                required
                            />
                        </div>
                        <button type="submit">Login</button>
                    </form>
                </header>

            </div>
        </>
    );
}

export default Login;
