import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { supabase } from './supabaseClient'; // Import the Supabase client
import logo from './assets/header.png';
// import './Home.css'; // Create a CSS file for the home page

function Home() {
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    const [user, setUser] = useState(null);

    useEffect(() => {
        const checkSession = async () => {
            const { data: { session } } = await supabase.auth.getSession();
            if (!session) {
                navigate('/');
            }
            else {
                setUser(session.user);
            }
        };

        checkSession();
    }, [navigate]);

    const handleDeleteAccount = async () => {
        let userID = user.id;
        const { data, error } = await supabase
            .from('profiles')
            .update({ DELETE: true })
            .eq('id', userID)
        console.log(data);
        alert("Account Deleted, you will still be able to login with the same email for 5 business days.");
        navigate('/');
    };

    const handleLogout = async () => {
        await supabase.auth.signOut();
        navigate('/');
    };

    return (

        <div className="app-container">
            <header className="app-header">
                <div className="image-container">
                    <img src={logo} className="app-logo-home" alt="logo" />
                </div>
                <div className='intro-home'>
                    <p>{user?.email}</p>
                    <p>You are logged in!</p>
                    {error && <p className="error">{error}</p>}
                    <button onClick={handleDeleteAccount} className="delete-button">Delete Account</button>
                    <button onClick={handleLogout}>Logout</button>
                </div>
            </header>
        </div>
    );
}

export default Home;
